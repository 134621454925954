import { mapGetters } from "vuex";
import { URL } from "@/api/report.js";
import InputMoney from "@/components/inputMoney.vue";

export default {
  name: "toKhaiThueGtgt",
  components: {
    InputMoney,
  },
  data() {
    return {
      dataTableTaxReturn: {},
    };
  },
  created() {
    this.activeTabs = this.routeName;
    this.getDetailReport();
  },

  methods: {
    async getDetailReport() {
      this.$store.dispatch("setIsReportLoading", true);
      const params = {};
      params.company_id = this.selectCurrentCompany;
      params.report_type = this.routeName;
      params.report_year = this.getReportYear;
      params.report_period = this.getReportPeriod;
      if (
        this.selectCurrentCompany &&
        this.getReportPeriod &&
        this.getReportYear
      ) {
        const result = await this.$request({
          url: URL.REPORT,
          params,
        });
        const res = result.data;

        if (res.code == 200) {
          this.dataTableTaxReturn = res.data.report_data;
          this.$store.dispatch(
            "setTaxRefundProposal",
            res.data.tax_refund_proposal
          );
          this.$store.dispatch("setSubmitReport", res.data.submit_report);
          this.$store.dispatch(
            "setValueRefundProposal",
            res.data.report_data.tax_refund_proposal
          );
          this.$store.dispatch("setIsDownloadable", res.data.is_downloadable);
          this.$store.dispatch("setIsDownloadProcessing", res.data.is_download_processing);
          this.$store.dispatch("setIsLargePDF", res.data.is_large_pdf);
          if (this.dataTableTaxReturn.ct05) {
            this.dataTableTaxReturn.ct05 = this.dataTableTaxReturn.ct05
              .split("")
              .join(" ");
            this.dataTableTaxReturn.ct13 = this.dataTableTaxReturn.ct13
              .split("")
              .join(" ");
          }
        }
      }
      this.$store.dispatch("setIsReportLoading", false);
    },
  },

  computed: {
    ...mapGetters([
      "selectCurrentCompany",
      "getReportPeriod",
      "getReportYear",
      "getSubmitReport",
      "getValueRefundProposal",
      "getTargetCompany",
    ]),

    routeName() {
      return this.$route.name;
    },

    changeCompanyOrYearOrdeclaration() {
      if (
        this.selectCurrentCompany &&
        this.getReportPeriod &&
        this.getReportYear
      ) {
        return `${this.selectCurrentCompany}-${this.getReportPeriod}-${this.getReportYear}`;
      }
    },
  },

  watch: {
    changeCompanyOrYearOrdeclaration() {
      this.getDetailReport();
    },
    getValueRefundProposal() {
      this.getDetailReport();
    },
  },
};
